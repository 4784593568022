import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonContent,
  IonIcon,
  IonButton,
  IonCard,
  IonCardContent,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import NavBar from '../../Shared/NavBar';
import SideBar from '../../Shared/SideBar';
import { useRecoilState } from 'recoil';
import employerService, { employerState } from '../../Shared/services/EmployerService';
import auth, { userState } from '../../Shared/services/auth';
import { add } from 'ionicons/icons';
import NewAnnouncementModal from './components/modal';
import { Announcement, AnnouncementComponent } from './components/announcement';

function AnnouncementsPage() {
  const [user, setUser] = useRecoilState<any>(userState);
  const [employer, setEmployer] = useRecoilState<any>(employerState);
  const [selectedTab, setSelectedTab] = useState('active');

  useEffect(() => {
    auth.GetUser().then((res: any) => {
      if (employer == null) {
        employerService.GetEmployer().then((emp: any) => {
          setEmployer(emp.data);
        });
      }
      setUser(res.data);
    });
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleNewAnnouncement = () => {
    setShowModal(true);
  };

  const activeAnnouncements: Announcement[] = [
    {
      id: 1,
      title: 'Summer Sale',
      description: 'Get 50% off on all summer items!',
      image: 'https://via.placeholder.com/300x200?text=Summer+Sale',
      startDate: '2024-06-01',
      endDate: '2024-08-31',
    },
  ];

  const pastAnnouncements: Announcement[] = [
    {
      id: 2,
      title: 'Spring Collection Launch',
      description: 'Check out our new spring collection!',
      image: 'https://via.placeholder.com/300x200?text=Spring+Collection',
      startDate: '2024-03-01',
      endDate: '2024-05-31',
    },
  ];

  const handleEdit = (id: number) => {
    console.log(`Editing announcement with id: ${id}`);
  };

  const handleArchive = (id: number) => {
    console.log(`Archiving announcement with id: ${id}`);
  };

  return (
    <IonPage>
      <div style={{ display: 'flex' }}>
        <div>
          <SideBar employer={employer} />
        </div>
        <NavBar user={user} />
        <div style={{ flex: 1, marginTop: 0, padding: '48px 16px 16px 16px' }}>
          <IonContent fullscreen className='main-content'>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3>Announcements</h3>

                <IonButton color='dark' style={{ fontSize: 16 }} onClick={handleNewAnnouncement}>
                  <IonIcon slot='icon-only' icon={add} />
                  New
                </IonButton>
              </div>
            </div>
            <div>
              <IonSegment
                value={selectedTab}
                onIonChange={(e) => setSelectedTab(e.detail.value as string)}
                style={{
                  width: '240px',
                }}
              >
                <IonSegmentButton value='active'>
                  <IonLabel>Active</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value='past'>
                  <IonLabel>Past</IonLabel>
                </IonSegmentButton>
              </IonSegment>

              {selectedTab === 'active' && (
                <>
                  {activeAnnouncements.map((announcement) => (
                    <AnnouncementComponent
                      key={announcement.id}
                      announcement={announcement}
                      isActive={true}
                      onEdit={handleEdit}
                      onArchive={handleArchive}
                    />
                  ))}
                </>
              )}

              {selectedTab === 'past' && (
                <>
                  {pastAnnouncements.map((announcement) => (
                    <AnnouncementComponent
                      key={announcement.id}
                      announcement={announcement}
                      isActive={false}
                      onEdit={handleEdit}
                    />
                  ))}
                </>
              )}
            </div>
          </IonContent>
        </div>
      </div>
      {showModal && (
        <NewAnnouncementModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onCreateAnnouncement={(announcement) => {
            console.log(announcement);
          }}
        />
      )}
    </IonPage>
  );
}

export default AnnouncementsPage;
