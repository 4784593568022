import React, { useState, useRef } from 'react';
import {
  IonModal,
  IonButton,
  IonInput,
  IonLabel,
  IonItem,
  IonIcon,
  IonImg,
  IonDatetime,
  IonPopover,
  IonContent,
  IonTextarea,
} from '@ionic/react';
import { arrowForward, camera, calendar } from 'ionicons/icons';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateAnnouncement: (announcement: Announcement) => void;
}

interface Announcement {
  title: string;
  description: string;
  image: File | null;
  startDate: string;
  endDate: string;
}

const NewAnnouncementModal: React.FC<ModalProps> = ({ isOpen, onClose, onCreateAnnouncement }) => {
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleCreateAnnouncement = () => {
    const announcement: Announcement = {
      title,
      description,
      image,
      startDate,
      endDate,
    };
    onCreateAnnouncement(announcement);
    onClose();
  };

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) => (e: CustomEvent) => {
      const value = e.detail.value as string | undefined;
      setter(value ?? '');
    };

  const handleDateChange = (
    value: string | string[] | null | undefined,
    setter: React.Dispatch<React.SetStateAction<string>>,
    setShowPicker: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    console.log(value);
    if (typeof value === 'string') {
      setter(value);
    } else if (Array.isArray(value) && value.length > 0) {
      setter(value[0]);
    } else {
      setter('');
    }
    setShowPicker(false);
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerImageUpload = () => {
    fileInputRef.current?.click();
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} className='mx-modal-narrow'>
      <IonContent>
        <div style={{ padding: 20 }}>
          <input
            type='file'
            accept='image/*'
            onChange={handleImageUpload}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
            {imagePreview ? (
              <IonImg
                src={imagePreview}
                style={{
                  width: '100px',
                  height: '100px',
                  objectFit: 'cover',
                  borderRadius: 10,
                  marginRight: 15,
                }}
              />
            ) : (
              <div
                style={{
                  width: '100px',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#f4f4f4',
                  cursor: 'pointer',
                  borderRadius: 10,
                  marginRight: 15,
                }}
                onClick={triggerImageUpload}
              >
                <IonIcon icon={camera} size='large' />
              </div>
            )}
            <IonButton
              onClick={triggerImageUpload}
              color='dark'
              style={{ flexGrow: 1, height: '44px' }}
            >
              {image ? 'Change Image' : 'Upload Image'}
            </IonButton>
          </div>
          <IonInput
            placeholder='Title'
            value={title}
            onIonChange={handleInputChange(setTitle)}
            style={{
              width: '100%',
              border: '1px solid black',
              padding: 6,
              borderBottom: '2px solid black',
              borderRight: '2px solid black',
              borderRadius: 10,
              marginTop: 5,
            }}
          ></IonInput>
          <IonTextarea
            placeholder='Description'
            value={description}
            onIonChange={handleInputChange(setDescription)}
            style={{
              width: '100%',
              border: '1px solid black',
              borderBottom: '2px solid black',
              borderRight: '2px solid black',
              borderRadius: 10,
              marginTop: 10,
              padding: '8px',
            }}
            rows={4}
            autoGrow={true}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 10,
              gap: 8,
              border: '1px solid #EBEBEB',
              borderRadius: 10,
              padding: 4,
            }}
          >
            <IonItem button onClick={() => setShowStartDatePicker(true)} style={{ flex: 1 }}>
              <IonLabel>{startDate || 'Start Date'}</IonLabel>
              <IonIcon icon={calendar} slot='end' />
            </IonItem>
            <IonIcon icon={arrowForward} />
            <IonItem button onClick={() => setShowEndDatePicker(true)} style={{ flex: 1 }}>
              <IonLabel>{endDate || 'End Date'}</IonLabel>
              <IonIcon icon={calendar} slot='end' />
            </IonItem>
          </div>
          <IonButton
            onClick={handleCreateAnnouncement}
            expand='block'
            color='dark'
            style={{ marginTop: 20 }}
          >
            Create Announcement
          </IonButton>
          <IonButton onClick={onClose} expand='block' color='light' style={{ marginTop: 10 }}>
            Cancel
          </IonButton>
        </div>
      </IonContent>

      <IonPopover
        isOpen={showStartDatePicker}
        onDidDismiss={() => setShowStartDatePicker(false)}
        arrow={false}
        style={{
          '--width': '320px',
          '--max-width': '90%',
          '--height': 'auto',
          '--background': 'var(--ion-background-color, #fff)',
        }}
      >
        <IonDatetime
          value={startDate}
          presentation='date'
          onIonChange={(e) =>
            handleDateChange(e.detail.value, setStartDate, setShowStartDatePicker)
          }
          max={endDate || undefined}
          style={{ width: '100%', maxWidth: '100%', height: 'auto' }}
        >
          <IonButton slot='buttons' onClick={() => setShowStartDatePicker(false)} color='dark'>
            Done
          </IonButton>
        </IonDatetime>
      </IonPopover>

      <IonPopover
        isOpen={showEndDatePicker}
        onDidDismiss={() => setShowEndDatePicker(false)}
        arrow={false}
        style={{
          '--width': '320px',
          '--max-width': '90%',
          '--height': 'auto',
          '--background': 'var(--ion-background-color, #fff)',
        }}
      >
        <IonDatetime
          value={endDate}
          presentation='date'
          onIonChange={(e) => {
            handleDateChange(e.detail.value, setEndDate, setShowEndDatePicker);
          }}
          min={startDate || undefined}
          style={{ width: '100%', maxWidth: '100%', height: 'auto' }}
        >
          <IonButton slot='buttons' color='dark' onClick={() => setShowEndDatePicker(false)}>
            Done
          </IonButton>
        </IonDatetime>
      </IonPopover>
    </IonModal>
  );
};

export default NewAnnouncementModal;
