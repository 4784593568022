import { IonButton, IonContent, IonIcon, IonModal, IonSpinner } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import flatMap from 'lodash/flatMap';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import teamService from '../Shared/services/TeamService';
import { useHistory } from 'react-router';

interface Props {
  team: any;
  controlGroups: any;
  reload: () => void;
}

export default function NewMemberModal(props: Props) {
  const [showModal, setShowModal] = useState(false);

  const [firstName, setFirstName] = useState<any>(null);
  const [lastName, setLastName] = useState<any>(null);
  const [emailAddress, setEmailAddress] = useState<any>(null);
  const [altEmailAddress, setAltEmailAddress] = useState<any>(null);
  const [telephone, setTelephone] = useState<any>();
  const [jobTitle, setJobTitle] = useState<any>(null);
  const [manager, setManager] = useState<any>(null);
  const [controlGroup, setControlGroup] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [anniversaryDate, setAnniversaryDate] = useState<any>(null);
  const [birthdate, setBirthdate] = useState<any>(null);
  const [eeId, setEeId] = useState<any>(null);
  const [teamOptions, setTeamOptions] = useState<any>([]);
  const [controlGroupOptions, setControlGroupOptions] = useState<any>([]);
  const [teamMembersLoading, setTeamMembersLoading] = useState<boolean>(false);
  const [teamSearchTimeout, setTeamSearchTimeout] = useState<any>(null);

  const history = useHistory();

  useEffect(() => {
    const options: any = [];
    const wholeTeam = flatMap(props.team).filter((t: any) => {
      return t.name != null && t.name !== '' && t.name !== ' ';
    });

    wholeTeam.map((t: any) => {
      options.push({ value: t.id, label: t.name });
    });
    setTeamOptions(options);

    const cgOptions: any = [];

    props.controlGroups.map((cg: any) => {
      cgOptions.push({ value: cg.id, label: cg.name });
    });
    setControlGroupOptions(cgOptions);
  }, [props.team, props.controlGroups]);

  function formValid() {
    let valid = true;
    if (!firstName) valid = false;
    if (!lastName) valid = false;
    if (!emailAddress) valid = false;
    if (!controlGroup) valid = false;

    return valid;
  }

  function addMember() {
    const newMember = {
      FirstName: firstName,
      LastName: lastName,
      EmailAddress: emailAddress,
      AltEmailAddress: altEmailAddress,
      Phone: telephone,
      JobTitle: jobTitle,
      manager: manager,
      ControlGroupId: controlGroup,
      StartDate: startDate,
      AnniversaryDate: anniversaryDate,
      Birthdate: birthdate,
      EeId: eeId,
      CurrentManagerId: manager,
    };
    console.log('new member', newMember);
    teamService
      .addMember(newMember)
      .then(() => {
        props.reload();

        clearForm();
        setShowModal(false);
      })
      .catch((err: any) => {
        console.log('error', err);
      });
  }

  function clearForm() {
    setFirstName(null);
    setLastName(null);
    setEmailAddress(null);
    setAltEmailAddress(null);
    setJobTitle(null);
    setManager(null);
    setControlGroup(null);
    setAnniversaryDate(null);
    setStartDate(null);
    setBirthdate(null);
    setEeId(null);
    setTelephone(null);
  }

  return (
    <div>
      <IonButton
        style={{ zIndex: 100000000 }}
        onClick={() => {
          setShowModal(true);
        }}
        color='dark'
      >
        Add member
      </IonButton>
      <IonModal
        className='mx-modal'
        style={{ borderRadius: 22 }}
        isOpen={showModal}
        onDidDismiss={() => {
          clearForm();
          setShowModal(false);
        }}
      >
        <IonContent>
          <div
            style={{
              fontSize: 44,
              textAlign: 'right',
              paddingTop: 22,
              paddingRight: 22,
            }}
          >
            <IonIcon
              onClick={() => {
                clearForm();
                setShowModal(false);
              }}
              icon={closeCircleOutline}
            />
          </div>

          <div style={{ padding: 22 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ fontWeight: 'bold', flex: 1 }}> Yay! Enter new member info below.</div>
              {localStorage.getItem('bulkedit') == 'true' && (
                <div
                  style={{
                    color: 'teal',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setShowModal(false);
                    history.push('/import');
                  }}
                >
                  Upload multiple via CSV
                </div>
              )}
            </div>
            <div className='lines'></div>
            <div style={{ display: 'flex' }}>
              <div style={{ paddingTop: 22, flex: 1 }}>
                &nbsp;&nbsp; <b>First Name*</b>
                <input
                  className='mx-border'
                  style={{ marginTop: 0 }}
                  placeholder='First Name'
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>
              <div style={{ paddingTop: 22, flex: 1 }}>
                &nbsp;&nbsp; <b>Last Name*</b>
                <input
                  className='mx-border'
                  style={{ marginTop: 0 }}
                  placeholder='Last Name'
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ paddingTop: 22, flex: 1 }}>
                &nbsp;&nbsp; <b>Email Address*</b>
                <input
                  className='mx-border'
                  style={{ marginTop: 0 }}
                  placeholder='Email Address'
                  value={emailAddress}
                  onChange={(e) => {
                    setEmailAddress(e.target.value);
                  }}
                />
              </div>
              <div style={{ paddingTop: 22, flex: 1 }}>
                &nbsp;&nbsp; Alternate Email Address
                <input
                  className='mx-border'
                  style={{ marginTop: 0 }}
                  placeholder='Alternate Email Address'
                  value={altEmailAddress}
                  onChange={(e) => {
                    setAltEmailAddress(e.target.value);
                  }}
                />
              </div>
            </div>
            <div>
              <div style={{ paddingTop: 18 }}>
                &nbsp;&nbsp; Phone
                <input
                  className='mx-border'
                  style={{ marginTop: 0 }}
                  placeholder='Telephone'
                  value={telephone}
                  onChange={(e) => {
                    setTelephone(e.target.value);
                  }}
                />
              </div>
            </div>
            <div style={{ paddingTop: 22 }}>
              &nbsp;&nbsp; <b>Talent Segment*</b>
              <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                <Select
                  required={true}
                  placeholder=' &nbsp; Select...'
                  options={controlGroupOptions}
                  onChange={(e: any) => {
                    console.log(e);
                    setControlGroup(e.value);
                  }}
                />
              </div>
            </div>
            <div style={{ paddingTop: 18 }}>
              &nbsp;&nbsp; Start Date
              <div style={{ paddingLeft: 20, fontSize: 12, color: '#4e4e4e' }}>
                If you set a Start Date, your new member will be approved, gain access, and receive
                onboarding communications on that day without any additional action from you or your
                team.
              </div>
              <input
                className='mx-border'
                style={{ marginTop: 0 }}
                placeholder='Start Date'
                value={startDate}
                type='date'
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </div>{' '}
            <div style={{ paddingTop: 22 }}>
              <div style={{ display: 'flex', height: 24 }}>
                <div>&nbsp;&nbsp; Manager</div>
                <div style={{ paddingLeft: 8, paddingTop: 0 }}>
                  {teamMembersLoading && <IonSpinner name='dots' />}
                </div>
              </div>
              <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                {/** TODO - CLEAN - Update the below to use components/MemberIdSelector */}
                <Select
                  required={false}
                  placeholder=' &nbsp; Select...'
                  options={teamOptions}
                  onInputChange={(e: any) => {
                    if (e.length === 0) {
                      return;
                    }
                    setTeamMembersLoading(true);
                    clearTimeout(teamSearchTimeout);
                    setTeamSearchTimeout(
                      setTimeout(() => {
                        teamService
                          .allTeamMembers({
                            search: e,
                          })
                          .then((res: any) => {
                            console.log(res.data);
                            const options: any = [];
                            for (const m of res.data.members) {
                              options.push({
                                value: m.id,
                                label: m.name,
                              });
                            }
                            setTeamOptions(options);
                          })
                          .finally(() => setTeamMembersLoading(false));
                        console.log(e);
                      }, 1000),
                    );

                    console.log(e);
                  }}
                  onChange={(e: any) => {
                    console.log(e);
                    setManager(e.value);
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ paddingTop: 22, flex: 1 }}>
                &nbsp;&nbsp; Employee ID
                <input
                  className='mx-border'
                  style={{ marginTop: 0 }}
                  placeholder='Employee ID'
                  value={eeId}
                  onChange={(e) => {
                    setEeId(e.target.value);
                  }}
                />
              </div>
              <div style={{ paddingTop: 22, flex: 1 }}>
                &nbsp;&nbsp; Job Title
                <input
                  className='mx-border'
                  style={{ marginTop: 0 }}
                  placeholder='Job Title'
                  value={jobTitle}
                  onChange={(e) => {
                    setJobTitle(e.target.value);
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ paddingTop: 18, flex: 1 }}>
                &nbsp;&nbsp; Anniversary Date
                <div style={{ paddingLeft: 20, fontSize: 12, color: '#4e4e4e' }}>
                  When the new member started/s working
                </div>
                <input
                  type='date'
                  className='mx-border'
                  style={{ marginTop: 0 }}
                  placeholder='Anniversary Date'
                  value={anniversaryDate}
                  onChange={(e) => {
                    setAnniversaryDate(e.target.value);
                  }}
                />
              </div>
              <div style={{ paddingTop: 18, flex: 1 }}>
                &nbsp;&nbsp; Birthday
                <div style={{ paddingLeft: 20, fontSize: 12, color: '#4e4e4e' }}>
                  When was their birthday?
                </div>
                <input
                  type='date'
                  className='mx-border'
                  style={{ marginTop: 0 }}
                  placeholder='Birthday'
                  value={birthdate}
                  onChange={(e) => {
                    setBirthdate(e.target.value);
                  }}
                />
              </div>
            </div>
            <div style={{ paddingTop: 22, marginBottom: 50 }}>
              <IonButton
                disabled={!formValid()}
                onClick={() => {
                  addMember();
                }}
                expand='block'
                color='dark'
              >
                Create Member 👏
              </IonButton>
              <div
                style={{
                  paddingLeft: 20,
                  fontSize: 12,
                  color: '#4e4e4e',
                  textAlign: 'center',
                }}
              >
                Missing required* fields
                {!firstName && <div>First Name</div>}
                {!lastName && <div>Last Name</div>}
                {!emailAddress && <div>Email Address</div>}
                {!controlGroup && <div>Talent Segement</div>}
              </div>
            </div>
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
}
