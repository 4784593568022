import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonImg,
  IonIcon,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react';
import { createOutline, archiveOutline } from 'ionicons/icons';

export interface Announcement {
  id: number;
  title: string;
  description: string;
  image: string;
  startDate: string;
  endDate: string;
}

export const AnnouncementComponent: React.FC<{
  announcement: Announcement;
  isActive: boolean;
  onEdit: (id: number) => void;
  onArchive?: (id: number) => void;
}> = ({ announcement, isActive, onEdit, onArchive }) => {
  return (
    <IonCard style={{ margin: '10px' }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '120px 1fr auto',
          gridTemplateRows: 'auto 1fr',
          gridTemplateAreas: `
          "image header actions"
          "image content content"
        `,
          height: '120px',
        }}
      >
        <IonImg
          src={announcement.image}
          alt={announcement.title}
          style={{
            gridArea: 'image',
            width: '120px',
            height: '120px',
            objectFit: 'cover',
          }}
        />
        <IonCardHeader
          style={{
            gridArea: 'header',
            padding: '8px 12px 0',
            alignSelf: 'start',
          }}
        >
          <IonCardTitle
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              marginBottom: '4px',
            }}
          >
            {announcement.title}
          </IonCardTitle>
          <IonCardSubtitle
            style={{
              fontSize: '12px',
              marginBottom: '4px',
            }}
          >
            {announcement.startDate} - {announcement.endDate}
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent
          style={{
            gridArea: 'content',
            padding: '0 12px 8px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            fontSize: '14px',
          }}
        >
          {announcement.description}
        </IonCardContent>
        <div
          style={{
            gridArea: 'actions',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '4px',
            gap: '4px',
          }}
        >
          {isActive && onArchive && (
            <IonButton
              color='dark'
              size='small'
              onClick={() => onArchive(announcement.id)}
              style={{
                '--padding-start': '4px',
                '--padding-end': '4px',
              }}
            >
              <IonIcon icon={archiveOutline} />
            </IonButton>
          )}
          <IonButton
            color='dark'
            size='small'
            onClick={() => onEdit(announcement.id)}
            style={{
              '--padding-start': '4px',
              '--padding-end': '4px',
            }}
          >
            <IonIcon icon={createOutline} />
          </IonButton>
        </div>
      </div>
    </IonCard>
  );
};
